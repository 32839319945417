
import { getPost } from '@/api/post';
import { UserModule } from '@/store/user';
import { Vue, Component } from 'vue-property-decorator';
import ApplyForm from '@/components/apply/form.vue';

@Component({
  name: 'AppNoticeDetail',
  components: {
    ApplyForm,
  },
})
export default class extends Vue {
  mounted() {
    this.getPost();
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isLogined() {
    return UserModule.token;
  }

  private post: any = null;

  private getPost() {
    if (this.$route.params.postUid) {
      getPost(this.$route.params.postUid).then((res) => {
        this.post = res.data;
      });
    } else {
      this.$router.push({ name: 'AppMain' });
    }
  }
}
